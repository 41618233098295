import { useEffect, useLayoutEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Router } from "react-router-dom";

import { createBrowserHistory } from "history";

import { CONSTANT } from "./constant/common";
import Routers from "./routes";
import "./styles/globals.scss";

const history = createBrowserHistory();
const CustomRouter = ({ history, ...props }: any) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });
  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
function App() {
  useEffect(() => {
    if (window.location.pathname === "/") {
      window.location.href = "/admin/login";
    }
  }, []);

  return (
    <CustomRouter history={history}>
      <GoogleReCaptchaProvider
        reCaptchaKey={CONSTANT.RECAPTCHA_KEY}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: "head",
          nonce: undefined
        }}
      >
        <div className="App">
          <Routers />
        </div>
      </GoogleReCaptchaProvider>
    </CustomRouter>
  );
}
export default App;
