/* eslint-disable @typescript-eslint/no-unused-vars */
import { lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";

import { Box, CircularProgress } from "@mui/material";

import { ConfirmProvider } from "material-ui-confirm";

import MainLayoutPrivate from "../layouts/MainLayoutPrivate";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <ConfirmProvider>
        <Component {...props} />
      </ConfirmProvider>
    </Suspense>
  );
};

const Login = Loadable(lazy(() => import("../pages/login")));
const Home = Loadable(lazy(() => import("../pages/home")));
const Company = Loadable(lazy(() => import("../pages/company")));
const User = Loadable(lazy(() => import("../pages/user")));
const Recruit = Loadable(lazy(() => import("../pages/recruit")));
const Transaction = Loadable(lazy(() => import("../pages/transaction")));
const Package = Loadable(lazy(() => import("../pages/package")));
const ReleaseManagement = Loadable(
  lazy(() => import("../pages/release-management"))
);
const OptimizeCVPage = Loadable(lazy(() => import("../pages/optimize-cv")));
const EmailTemplatePage = Loadable(
  lazy(() => import("../pages/email-template"))
);
const ExtendTimeJobHolidayPage = Loadable(
  lazy(() => import("../pages/extend-time-job-holiday"))
);
const BadKeywordPage = Loadable(lazy(() => import("../pages/bad-keyword")));
const Logger = Loadable(lazy(() => import("../container/Admin/Logger/page")));
const LoggerDetail = Loadable(
  lazy(() => import("../container/Admin/Logger/Detail/LoggerDetail"))
);
const ConfirmCV = Loadable(lazy(() => import("../container/Admin/ConfirmCV")));
const ConfirmCvDetail = Loadable(
  lazy(() => import("../container/Admin/ConfirmCV/ConfirmCvDetail"))
);
const ConfirmCvDetailByFile = Loadable(
  lazy(() => import("../container/Admin/ConfirmCV/ConfirmCvDetailByFile"))
);
const UploadCv = Loadable(
  lazy(() => import("../container/Admin/ConfirmCV/UploadCv"))
);
const InputUploadCv = Loadable(
  lazy(() => import("../container/Input/UploadCV"))
);
const InputCVList = Loadable(lazy(() => import("../container/Input/CvList")));
const ViewCvById = Loadable(
  lazy(() => import("../container/Input/CvList/ViewCvById"))
);
const InputCVListAdmin = Loadable(
  lazy(() => import("../container/Admin/InputCV"))
);
const InputEmployerCVListAdmin = Loadable(
  lazy(() => import("../container/Admin/InputCV/InputCvOfEmployer"))
);
const InputFileEmployerCvListAdmin = Loadable(
  lazy(() => import("../container/Admin/InputCV/InputCvFileOfEmployer"))
);
const ViewCvByIdAdmin = Loadable(
  lazy(() => import("../container/Admin/InputCV/ViewCvById"))
);
const AdminBlogCategoryManagement = Loadable(
  lazy(() => import("../container/Admin/BlogCategory"))
);
const AdminBlogManagement = Loadable(
  lazy(() => import("../container/Admin/Blog"))
);
const AdminCreateBlog = Loadable(
  lazy(() => import("../container/Admin/Blog/CreateBlog"))
);
const AdminUpdateBlog = Loadable(
  lazy(() => import("../container/Admin/Blog/UpdateBlog"))
);
const ContentBlogCategoryManagement = Loadable(
  lazy(() => import("../container/Content/BlogCategory"))
);
const ContentBlogManagement = Loadable(
  lazy(() => import("../container/Content/Blog"))
);
const ContentCreateBlog = Loadable(
  lazy(() => import("../container/Content/Blog/CreateBlog"))
);
const ContentUpdateBlog = Loadable(
  lazy(() => import("../container/Content/Blog/UpdateBlog"))
);
const SkillTermManagement = Loadable(
  lazy(() => import("../container/Admin/SkillTerm"))
);
const QAndAFromCandidateAndHrComp = Loadable(
  lazy(() => import("../container/Admin/QAndAFromCandidateAndHr/page/index"))
);
const QAndAFromCandidateAndHrDetailComp = Loadable(
  lazy(
    () =>
      import(
        "../container/Admin/QAndAFromCandidateAndHr/components/QAndAFromCandidateAndHrDetail"
      )
  )
);
const QAndAFromCandidateAndHrReplyComp = Loadable(
  lazy(
    () =>
      import(
        "../container/Admin/QAndAFromCandidateAndHr/components/QAndAFromCandidateAndHrReply"
      )
  )
);
const FAQManagement = Loadable(lazy(() => import("../pages/faq")));
const AppliedCvManagement = Loadable(lazy(() => import("../pages/cv-applied")));
const LimitDataManagement = Loadable(lazy(() => import("../pages/limit-data")));
const EmployerRefererManagement = Loadable(
  lazy(() => import("../pages/referer"))
);
const EducationTermManagement = Loadable(
  lazy(() => import("../container/Admin/EducationTerm/page"))
);
const ScoreDiaryManager = Loadable(
  lazy(() => import("../container/Admin/ScoreDiary/page"))
);
const ParseCv = Loadable(lazy(() => import("../pages/parseCv")));

const adminRoutes = [
  {
    path: "/",
    element: <MainLayoutPrivate />,
    children: [{ path: "/", element: <Home /> }]
  },
  {
    path: "/admin/login",
    children: [{ path: "/admin/login", element: <Login /> }]
  },
  {
    path: "/admin/company",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/company", element: <Company /> }]
  },
  {
    path: "/admin/user",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/user", element: <User /> }]
  },
  {
    path: "/admin/recruit",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/recruit", element: <Recruit /> }]
  },
  {
    path: "/admin/transaction",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/transaction", element: <Transaction /> }]
  },
  {
    path: "/admin/package",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/package", element: <Package /> }]
  },
  {
    path: "/admin/logger",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/logger", element: <Logger /> }]
  },
  {
    path: "/admin/logger/:id",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/logger/:id", element: <LoggerDetail /> }]
  },
  {
    path: "/admin/confirm-cv",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/confirm-cv", element: <ConfirmCV /> }]
  },
  {
    path: "/admin/confirm-cv/:id",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/confirm-cv/:id", element: <ConfirmCvDetail /> }]
  },
  {
    path: "/admin/confirm-cv-file/:id",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/confirm-cv-file/:id",
        element: <ConfirmCvDetailByFile />
      }
    ]
  },
  {
    path: "/admin/confirm-cv/upload",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/confirm-cv/upload", element: <UploadCv /> }]
  },
  {
    path: "/admin/input-cv",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/input-cv", element: <InputCVListAdmin /> }]
  },
  {
    path: "/admin/input-cv/:id",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/input-cv/:id", element: <ViewCvByIdAdmin /> }]
  },
  {
    path: "/admin/employer-input-cv",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/employer-input-cv",
        element: <InputEmployerCVListAdmin />
      }
    ]
  },
  {
    path: "/admin/employer-input-cv-file",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/employer-input-cv-file",
        element: <InputFileEmployerCvListAdmin />
      }
    ]
  },
  {
    path: "/admin/blog-category",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/blog-category",
        element: <AdminBlogCategoryManagement />
      }
    ]
  },
  {
    path: "/admin/blog",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/blog", element: <AdminBlogManagement /> }]
  },
  {
    path: "/admin/blog/create",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/blog/create", element: <AdminCreateBlog /> }]
  },
  {
    path: "/admin/blog/update/:id",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/blog/update/:id", element: <AdminUpdateBlog /> }]
  },
  {
    path: "/admin/skill-term",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/skill-term",
        element: <SkillTermManagement />
      }
    ]
  },
  {
    path: "/admin/education-term",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/education-term",
        element: <EducationTermManagement />
      }
    ]
  },
  {
    path: "/admin/limit-data",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/limit-data",
        element: <LimitDataManagement />
      }
    ]
  },
  {
    path: "/admin/faq",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/faq",
        element: <FAQManagement />
      }
    ]
  },
  {
    path: "/admin/cv-applied",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/cv-applied",
        element: <AppliedCvManagement />
      }
    ]
  },
  {
    path: "/admin/cv-applied/:id",
    element: <MainLayoutPrivate />,
    children: [
      { path: "/admin/cv-applied/:id", element: <AppliedCvManagement /> }
    ]
  },
  {
    path: "/admin/score-diary",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/score-diary", element: <ScoreDiaryManager /> }]
  },
  {
    path: "/admin/release-management",
    element: <MainLayoutPrivate />,
    children: [
      { path: "/admin/release-management", element: <ReleaseManagement /> }
    ]
  },
  {
    path: "/admin/referer",
    element: <MainLayoutPrivate />,
    children: [
      { path: "/admin/referer", element: <EmployerRefererManagement /> }
    ]
  },
  {
    path: "/admin/optimize-cv",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/optimize-cv", element: <OptimizeCVPage /> }]
  },
  {
    path: "/admin/email-template",
    element: <MainLayoutPrivate />,
    children: [
      { path: "/admin/email-template", element: <EmailTemplatePage /> }
    ]
  },
  {
    path: "/admin/extend-time-job-holiday",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/extend-time-job-holiday",
        element: <ExtendTimeJobHolidayPage />
      }
    ]
  },
  {
    path: "/admin/comment-questions-and-answers-job",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/comment-questions-and-answers-job",
        element: <QAndAFromCandidateAndHrComp />
      }
    ]
  },
  {
    path: "/admin/comment-questions-and-answers-job/:id",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/comment-questions-and-answers-job/:id",
        element: <QAndAFromCandidateAndHrDetailComp />
      }
    ]
  },
  {
    path: "/admin/comment-questions-and-answers-job/reply/:id",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/admin/comment-questions-and-answers-job/reply/:id",
        element: <QAndAFromCandidateAndHrReplyComp />
      }
    ]
  },
  {
    path: "/admin/bad-keyword",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/bad-keyword", element: <BadKeywordPage /> }]
  },
  {
    path: "/admin/parse-cv",
    element: <MainLayoutPrivate />,
    children: [{ path: "/admin/parse-cv", element: <ParseCv /> }]
  }
];

const inputRoutes = [
  {
    path: "/input/cv",
    element: <MainLayoutPrivate />,
    children: [{ path: "/input/cv", element: <InputCVList /> }]
  },
  {
    path: "/input/upload-cv",
    element: <MainLayoutPrivate />,
    children: [{ path: "/input/upload-cv", element: <InputUploadCv /> }]
  },
  {
    path: "/input/upload-cv/:id",
    element: <MainLayoutPrivate />,
    children: [{ path: "/input/upload-cv/:id", element: <ViewCvById /> }]
  }
];

const contentRoutes = [
  {
    path: "/content/blog",
    element: <MainLayoutPrivate />,
    children: [{ path: "/content/blog", element: <ContentBlogManagement /> }]
  },
  {
    path: "/content/blog/create",
    element: <MainLayoutPrivate />,
    children: [{ path: "/content/blog/create", element: <ContentCreateBlog /> }]
  },
  {
    path: "/content/blog/update/:id",
    element: <MainLayoutPrivate />,
    children: [
      { path: "/content/blog/update/:id", element: <ContentUpdateBlog /> }
    ]
  },
  {
    path: "/content/blog-category",
    element: <MainLayoutPrivate />,
    children: [
      {
        path: "/content/blog-category",
        element: <ContentBlogCategoryManagement />
      }
    ]
  }
];

export default function Router() {
  return useRoutes([...adminRoutes, ...inputRoutes, ...contentRoutes]);
}
